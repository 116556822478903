<template>
  <ul
    class="grid gap-x-6 gap-y-2 my-8 grid-cols-1"
    :class="noCaption ? 'md:grid-cols-4' : 'md:grid-cols-3 '"
  >
    <li v-for="(file, index) in files" :key="file.file.id">
      <EditCaptionAndFile
        v-model="files[index]"
        :is-first="index === 0"
        :is-last="index === files.length - 1"
        :allow-videos="allowVideos"
        :disable-caption="filesCount === 1"
        :no-caption="noCaption"
        :is-file-editable="isFileEditable"
        @delete="deleteFile(file.file.id)"
        @move-left="moveLeft(index)"
        @move-right="moveRight(index)"
      />
    </li>
  </ul>
  <p v-if="filesCount === 0" class="mb-16 text-center">No images to display.</p>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ContentSocialFileDto } from '@/api/contents';
import { EditCaptionAndFile } from '~/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents';

type Props = {
  allowVideos?: boolean;
  noCaption?: boolean;
  isFileEditable?: boolean;
};

defineProps<Props>();

const files = defineModel<ContentSocialFileDto[]>({
  required: true,
});

const filesCount = computed(() => {
  return files.value.length;
});

const deleteFile = (id: number) => {
  files.value = files.value.filter((file) => file.file.id !== id);
};

const moveFile = (from: number, to: number) => {
  const lowerIndex = Math.min(from, to);
  const higherIndex = Math.max(from, to);

  files.value = [
    ...files.value.slice(0, lowerIndex),
    files.value[higherIndex],
    files.value[lowerIndex],
    ...files.value.slice(higherIndex + 1),
  ];
};

const moveLeft = (index: number) => {
  if (index - 1 < 0) return;

  moveFile(index, index - 1);
};

const moveRight = (index: number) => {
  if (index + 1 > files.value.length) return;

  moveFile(index, index + 1);
};
</script>
